<template>
  <div>
    <el-dialog v-if="dialogseeid" top="5vh" title="证件详情" :visible.sync="dialogseeid" width="1000px">
      <div class="dio scorr-roll">
        <el-row :gutter="40">
          <el-col :span="12">
            <div class="twotle">会员基础信息</div>
            <div class="hedtxt">
              <div class="fx">
                <div style="flex: 6">
                  企业名称： {{ cardData.enterprise_name || "无" }}
                </div>
                <div style="flex: 5">
                  单位注册类型： {{ cardData.register_type || "无" }}
                </div>
              </div>
              <div class="fx">
                <div style="flex: 6">
                  代码编号： {{ cardData.code_number || "无" }}
                </div>
                <div style="flex: 5">
                  会员类型： {{ cardData.member_name || "无" }}
                </div>
              </div>
              <div class="fx">
                <div style="flex: 6">
                  法人姓名： {{ cardData.legal_name || "无" }}
                </div>
                <div style="flex: 5">
                  注册手机号： {{ cardData.register_phone || "无" }}
                </div>
              </div>
              <div class="fx">
                <div style="flex: 6">
                  所在地区：{{ cardData.consignee_in_area || "无" }}
                </div>
                <div style="flex: 5">
                  详细地址：{{ cardData.detail_address || "无" }}
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="twotle">收货信息</div>
            <div class="hedtxt">
              <div class="fx">
                <div style="flex: 6">
                  收货人：{{ cardData.consignee || "无" }}
                </div>
                <div style="flex: 5">
                  收货人电话：{{ cardData.consignee_phone || "无" }}
                </div>
              </div>
              <div class="fx">
                <div style="flex: 6">
                  所在地区：{{ cardData.consignee_in_area || "无" }}
                </div>
                <div style="flex: 5">
                  详细地址：{{ cardData.detail_address || "无" }}
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <div style="margin: 35px"></div>
        <div class="twotle">经营类别</div>
        <div class="hedtxt" v-if="cardData.business_category.length > 0">
          <div class="col-box">
            <div class="col" v-for="item in cardData.business_category" :key="item.id">
              <div class="col-title exceed" :title="item.name">
                {{ item.name }}
              </div>

              <div>: <span v-if="item.expiration_time=='9999-12-31 00:00:00'">长期</span>
              
              <span v-else>{{ item.expiration_time || "/" }}</span></div>
            </div>
          </div>
        </div>
        <div v-else>未设置经营类别</div>
        <div style="margin: 35px"></div>
        <div class="twotle">会员资质</div>
        <div class="hedtxt" style="color: #000">
          <el-row :gutter="24">
            <template v-if="qualificationData.length > 0">
              <el-col :span="6" v-for="item in qualificationData" :key="item.id">
                <div class="fx-1">
                  <div class="img-title exceed">{{ item.item_name }}</div>
                  <el-image style="width: 150px; height: 150px" :src="item.qualify_data[0] || ''" fit="scale-down"
                    :preview-src-list="item.qualify_data">
                  </el-image>
                </div>
              </el-col>
            </template>
            <template v-else>
              <el-col> 未上传资质 </el-col>
            </template>
          </el-row>
        </div>
      </div>
      <div slot="footer" class="btn-box">
        <el-button @click="updatahui" class="back_green bai" v-if="allJurisdiction.orderCenterList135">一键下载会员资料</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
const { mapState, mapActions } = createNamespacedHelpers("Order"); //vuex公共库
export default {
  props: ['qualificationData'],
  name: "CheckseeId",
  data() {
    return {
      dialogseeid: false,
      url: "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.jj20.com%2Fup%2Fallimg%2F1114%2F050421112027%2F210504112027-2-1200.jpg&refer=http%3A%2F%2Fimg.jj20.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1651903550&t=70a540173e745630a10e0cfa7ed01b7d",
    };
  },
  created() {
  },
  computed: {
    ...mapState(["cardData",]),
        ...commonIndex.mapState(['allJurisdiction']),
  },
  methods: {
    ...mapActions(["postdownloadUserInfo"]),
    init(row) {
      this.dialogseeid = true;
    },
    async updatahui() {
      let params = this.cardData
      // console.log( sessionStorage.getItem("userId"))
      let userId=sessionStorage.getItem("userId")
      userId=JSON.parse(userId)
      params.uid = userId
      let data = await this.postdownloadUserInfo(params);
      if (data.code == 200) {
        window.open(data.data, "");
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
  },
};
</script>
<style lang='scss' scoped>
.el-col-8 {
  text-align: left !important;
}
.el-dialog__title {
  line-height: 24px;
  font-size: 18px;
  font-weight: 800;
  color: #06B7AE;
}
</style>
<style lang="scss" scoped>
.hedtxt {
  color: #999;
  font-size: 14px;
  margin-top: 10px;
  padding-top: 15px;
  border-top: 1px solid #ccc;
  line-height: 25px;
}
.dio {
  width: 100%;
  max-height: 60vh;
}
.fx-1 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  .img-title {
    width: 100%;
    text-align: center;
  }
}
.twotle {
  color: #06B7AE;
  font-size: 15px;
  font-weight: 700;
}
.btn-box {
  width: 100%;
  height: 30px;

  .el-button {
    display: flex;
    align-items: center;
    background: #06B7AE;
    color: #fff;
  }
}
.col-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  .col {
    margin-left: 10px;
    margin-top: 20px;
    width: 30%;
    height: 20px;
    display: flex;
    justify-content: left;
    .col-title {
      width: 30%;
    }
  }
}
</style>
